import React from "react";
import { CollapseDropdown } from "../Dropdown";
import { useParams } from "react-router-dom";
import Lab from "../../images/cm-lab.jpg";

export default function DeptLaboratory(props) {
	const { branch } = useParams();
	return (
		<>
<h1 className="heading heading-top-left-line">Laboratories</h1>  
<div classname="maindiv1" >
		<div>
			<img style={{ width: 600, height: 290 }} src={Lab} alt="" />
		</div>

<b > Computer Lab </b>
<div className="mt-5">
				
				<table border="1px">
					<thead>
						<tr>
							<td>Sr No.</td>
							<td>Name of Laboratories</td>
							<td>Lab Utilization (Hours per week)</td>
							<td>Major Equipments</td>
							<td>Approx. cost of one equipment in rupees</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>01</td>
							<td>-</td>
							<td>24 </td>
							<td>-</td>
							<td> In Rupees </td>

						</tr>
						<tr>
							<td>02</td>
							<td>- </td>
							<td>24</td>
							<td>-</td>
							<td> In Rupees </td>

						</tr>
						<tr>
							<td>03</td>
							<td>-</td>
							<td>24</td>
							<td>-</td>
							<td> In Rupees </td>

						</tr>
						<tr>
							<td>04</td>
							<td>-</td>
							<td>24</td>
							<td>-</td>
							<td> In Rupees </td>

						</tr>
						<tr>
							<td>05</td>
							<td>-</td>
							<td>24</td>
							<td>-</td>
							<td> In Rupees </td>

						</tr>
						<tr>
							<td>06</td>
							<td>-</td>
							<td>24</td>
							<td>-</td>
							<td> In Rupees </td>

						</tr>
						<tr>
							<td></td>
							<td>
								<b>Total</b>
							</td>
							<td>
								<b>-</b>
							</td>
							<td>
								<b>-</b>
							</td>
							<td> In Rupees </td>

						</tr>
						
					</tbody>
				</table>
			</div>
		</div>
	</>

		
	
	);
}
