import React from "react";
import { CollapseDropdown } from "../Dropdown";
const pdf = "resultAnalysis/computer/2ndsem.pdf";
export default function DeptResultAnalysis({ data }) {
	return (
		<>
			<h1 className="heading heading-top-left-line">Time Table</h1>
			<div className="mt-5">
      {data.map(
				(dp) => <CollapseDropdown data={dp} />
			)}
			</div>
		</>
	);
}
