import React from "react";
import canteen from "../../images/Canteen.jpeg";

export default function () {
	return (
	<>
			<h1 className="heading heading-top-left-line">Canteen</h1>
			<div classname="maindiv1" >
		<div>
			<img style={{ width: 800, height: 500 }} src={canteen} alt="" />
		</div>
<br></br>

        <p classname="p1 ">
	<i>A college canteen is a vital part of the college environment, serving as a place to eat, socialize, and learn:<br/>
<b>Food:</b>
College canteens provide nutritious, hygienic food to students, staff, and campus residents. They may offer a variety of options, such as vegetarian and vegan choices, salad bars, and healthy snacks.  <br/>
<b>Socialization:</b>
Canteens are a place for students to relax, share experiences, and network with others. They can be a hub for academic and extracurricular discussions.  <br/>
<b>Health:</b>
Canteens can help students maintain a balanced diet and promote healthy lifestyles. They may offer special nutrition education programs. <br/>
<b>Convenience:</b>
Canteens are a convenient and affordable place to eat, especially for students with long days or who live close to campus. <br/>
<b>Discounts:</b>
Canteens may offer regular discounts to help students with tight budgets. </i> <br/>
</p>

		</div>

	</>
	);
}