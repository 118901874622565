import React from "react";
import Event from "../../images/Evenets_1.jpeg";
import { CollapseDropdown } from "../Dropdown";
const pdf = "resultAnalysis/computer/2ndsem.pdf";
export default function () {
	return (
	<>
<h1 className="heading heading-top-left-line">Various Events</h1>  
<div classname="maindiv1" >
		<div>
			<img style={{ width: 600, height: 290 }} src={Event} alt="" />
		</div>
<br></br>

        <p classname="p1 ">
	<i>A college canteen is a vital part of the college environment, serving as a place to eat, socialize, and learn:<br/>
<b>Event Name :</b>
College canteens provide nutritious, hygienic food to students, staff, and campus residents. They may offer a variety of options, such as vegetarian and vegan choices, salad bars, and healthy snacks.  <br/>
<b>About Event :</b>
Canteens are a convenient and affordable place to eat, especially for students with long days or who live close to campus. <br/>
<b></b>
</i> <br/>
</p>

		</div>

        <div>
			<img style={{ width: 600, height: 290 }} src={Event} alt="" />
		</div>
<br></br>

        <p classname="p1 ">
	<i>A college canteen is a vital part of the college environment, serving as a place to eat, socialize, and learn:<br/>
<b>Event Name :</b>
College canteens provide nutritious, hygienic food to students, staff, and campus residents. They may offer a variety of options, such as vegetarian and vegan choices, salad bars, and healthy snacks.  <br/>
<b>About Event :</b>
Canteens are a convenient and affordable place to eat, especially for students with long days or who live close to campus. <br/>
<b></b>
</i> <br/>
</p>

		

	</>
	);
}